<script>
import AnyPageTemplate from '../../../components/PageTemplates/AnyPage.vue';
import CollapsePanel from '../../../components/Panels/CollapsePanel.vue';
import { RST_LEVEL, RST_COMPETITOR } from '../../../LocalDB';
import CompetitionsConfigurationHelper from '../../../services/helpers/competitionsConfigurationHelper';

export default{
    components:{ AnyPageTemplate, CollapsePanel},
    data(){
        return {
            competition_id: '',
            competition: { name: '' },
            staff: { 
                totalCount: 0,
                judgeCount: 0,
                shadowJudgeCount: 0,
                judgesList: '',
                fieldDirectorCount: 0,
                shadowFieldDirectorCount: 0,
                fieldDirectorsList: '',
                scorerCount: 0,
                speakerCount: 0,
                djCount: 0,
            },
            levels: [ { name: this.$t('meta.levels.expert.title'), 
                        competitorsCount: 0,
                        categories: [
                            { code: "DI", competitorsList: [], isLoading: false },
                            { code: "DP", competitorsList: [], isLloading: false },
                            { code: "DT", competitorsList: [], isloading: false },
                            { code: "MI", competitorsList: [], isloading: false },
                            { code: "MP", competitorsList: [], isloading: false },
                            { code: "MT", competitorsList: [], isloading: false },
                        ]
                    } ]
        };
    },
    methods:{
        getStatus(competitor){
            var ret = [];
            if(competitor.isSelected) ret.push('qualified');
            if(competitor.isOpen) ret.push('open');
            if(competitor.isForfeited) ret.push('forfeited');
            if(competitor.isWithdrawn) ret.push('withdrawn');
            if(competitor.isAbsent) ret.push('absent');
            if(this.competition.isFederal && !competitor.isOpen){
                if(competitor.CATEGORY.isIndividual){
                    var l = competitor.LICENSEE;
                    if(l == null || !l.isRegistred) ret.push('no-licensee');
                    else if(!l.isCompetitor) ret.push('not-competitor-licensee');
                    else if(!l.isValidated) ret.push('not-validated-licensee');
                    else ret.push('federal')
                } else {
                    if(competitor.MEMBERS.filter(m => m.LICENSEE == null || !m.LICENSEE.isRegistred).length > 0) ret.push('no-licensee');
                    else if(competitor.MEMBERS.filter(m => !m.LICENSEE.isCompetitor).length > 0) ret.push('not-competitor-licensee');
                    else if(competitor.MEMBERS.filter(m => !m.LICENSEE.isValidated).length > 0) ret.push('not-validated-licensee');
                }
            }
            if(this.competition.isFederal && this.competition.isOpen && !competitor.isOpen) ret.push('federal');
            return ret.join(' '); 
        },
        async refresh(){
            await this.$showRefresher();
            this.competition = await CompetitionsConfigurationHelper.get(this.competition_id);
            var staff = await CompetitionsConfigurationHelper.getStaffWithCapabilitiesAsync(this.competition_id);
            this.staff.totalCount = staff.length;
            var judges = staff
                        .filter(s => s.CAPABILITIES.isJudge || s.CAPABILITIES.isJudgeShadow)
                        .map(s => s.isChiefJudge 
                                    ? `<span class="chief-judge">${s.PEOPLE.formal_shortname}</span>`
                                    : (s.CAPABILITIES.isJudge 
                                        ? `<span class="judge">${s.PEOPLE.formal_shortname}</span>`
                                        : `<span class="judge-shadow">${s.PEOPLE.formal_shortname}</span>`)
                                        );
            judges.sort((a,b) => a.localeCompare(b));
            this.staff.judgesList = judges.join(', ');
            this.staff.judgeCount = judges.length;
            this.staff.shadowJudgeCount = staff.filter(s => s.CAPABILITIES.isJudgeShadow).length;
            
            var fieldDirectors = staff
                        .filter(s => s.CAPABILITIES.isFieldDirector || s.CAPABILITIES.isFieldDirectorShadow)
                        .map(s => s.isFieldDirectorShadow
                                    ? `<span class="field-director-shadow">${s.PEOPLE.formal_shortname}</span>`
                                    : `<span class="field-director">${s.PEOPLE.formal_shortname}</span>`
                        )
            fieldDirectors.sort((a,b) => a.localeCompare(b));
            this.staff.fieldDirectorsList = fieldDirectors.join(', ');
            this.staff.fieldDirectorCount = fieldDirectors.length;
            this.staff.shadowFieldDirectorCount = staff.filter(s => s.CAPABILITIES.isFieldDirectorShadow).length;

            this.staff.scorerCount = staff.filter(s => s.CAPABILITIES.isScorer).length;
            this.staff.speakerCount = 0;
            this.staff.djCount = 0;

            //await CompetitionsConfigurationHelper.refreshLevels(this.competition_id);
            await CompetitionsConfigurationHelper.refreshLevelsAsync(this.competition_id, true, true, true);
            var levels = RST_LEVEL.query().where(l => l.competition_id == this.competition_id).get();
            this.levels = levels.map(l => { 
                var competitors = RST_COMPETITOR.query()
                    .where(c => c.level == l.level && c.competition_id == this.competition_id)
                    .orderBy(c => (c.isSelected ? 'a' : 'b') + String(c.subscription_order).padStart(5, '0'))
                    .get()
                    .map(c => { return { category: c.category, name: `<span class="competitor ${this.getStatus(c)}">${c.name}</span>`}; });
                var di = competitors.filter(c => c.category == 'DI').map(c => c.name);
                var dp = competitors.filter(c => c.category == 'DP').map(c => c.name);
                var dt = competitors.filter(c => c.category == 'DT').map(c => c.name);
                var mi = competitors.filter(c => c.category == 'MI').map(c => c.name);
                var mp = competitors.filter(c => c.category == 'MP').map(c => c.name);
                var mt = competitors.filter(c => c.category == 'MT').map(c => c.name);
                return {
                    code: l.code,
                    name: this.$t(`meta.levels.${l.code}.title`),
                    competitorsCount: competitors.length,
                    categories: [
                        { code: 'DI', competitorsList: di, isLoading: false },
                        { code: 'DP', competitorsList: dp, isLoading: false },
                        { code: 'DT', competitorsList: dt, isLoading: false },
                        { code: 'MI', competitorsList: mi, isLoading: false },
                        { code: 'MP', competitorsList: mp, isLoading: false },
                        { code: 'MT', competitorsList: mt, isLoading: false },
                    ]
                }
            });
            this.$hideRefresher();
        },
        async editJudges_click(){
            await this.$showLoader();
            this.$router.push({name: 'compet-admin-staff', params: { id: this.competition_id } })
        },
        async editCompetitors_click(level){
            await this.$showLoader();
            this.$router.push({name: 'compet-admin-competitors', params: { id: this.competition_id, level: level } })
        },
    },
    async mounted(){
        this.competition_id = this.$route.params.id;
        await this.refresh();
        this.$hideLoader();
    },
    watch:{
      '$route.params.id': {
        handler: function(id){
            this.competition_id = id;
            this.refresh();
        },
        deep: true,
        immediate: true
      } 
    }
}
</script>

<template>
    <any-page-template>
        <template #main-title-caption>{{ competition.name }}</template>
        <template #secondary-title-caption>{{ $t('pages.competition-admin.config.title') }}</template>

        <collapse-panel>
            <template #title>
                <b-button @click="editJudges_click">{{ $t('pages.competition-admin.config.staff.edit')}}</b-button>&nbsp;
                {{ $t('pages.competition-admin.config.staff.title')}} <b-badge>{{ staff.totalCount }}</b-badge>
            </template>
            <ul>
                <li><span class="staff-role" v-html="$tc('pages.competition-admin.config.staff.judge-count', staff.judgeCount) + '&nbsp;' + $tc('pages.competition-admin.config.staff.shadow-judge-count', staff.shadowJudgeCount)"></span><br>
                    <div v-html="staff.judgesList"></div>
                </li>
                <br/>
                <li><span class="staff-role" v-html="$tc('pages.competition-admin.config.staff.field-director-count', staff.fieldDirectorCount) + '&nbsp;' + $tc('pages.competition-admin.config.staff.shadow-field-director-count', staff.shadowFieldDirectorCount)"></span><br>
                    <div v-html="staff.fieldDirectorsList"></div>
                </li>
                <br/>
                <li><span class="staff-role" v-html="$tc('pages.competition-admin.config.staff.scorer-count', staff.scorerCount)"></span></li>
                <li><span class="staff-role" v-html="$tc('pages.competition-admin.config.staff.speaker-count', staff.speakerCount)"></span></li>
                <li><span class="staff-role" v-html="$tc('pages.competition-admin.config.staff.dj-count', staff.djCount)"></span></li>
            </ul>
        </collapse-panel>

        <collapse-panel v-for="level in levels" :key="level.name">
            <template #title>
                <b-button @click="editCompetitors_click(level.code)">{{ $t('pages.competition-admin.config.competitors.edit')}}</b-button>&nbsp;
                <span v-if="levels.length == 1">{{ $t('pages.competition-admin.config.competitors.title') }}</span>
                <b v-else>{{ $t(`meta.levels.${level.code}.title`) }}</b>
                &nbsp;<b-badge>{{ level.competitorsCount }}</b-badge>
            </template>
            <ul>
                <li v-for="category in level.categories" :key="`${level.name}-${category.code}`">
                    <span class="category-title" v-html="$tc(`meta.categories.${category.code}.title-n`, category.competitorsList.length)"></span>
                    <div v-html="category.competitorsList.join(', ')"></div>
                    <br v-if="category.competitorsList.length > 0"/>
                </li>
            </ul>
        </collapse-panel>

    </any-page-template>
</template>

<style>
    .competitor.open{ font-style: italic; font-weight: lighter;}
    /*.competitor.federal{ font-weight: bolder;}*/
    .competitor.qualified{ border-bottom: dotted blue 1px; }
    .competitor.forfeited{ text-decoration: line-through red;}
    .competitor.absent{ text-decoration: line-through red;}
    .competitor.withdrawn{ text-decoration: line-through;}

    .competitor.no-licensee{ color: red;}
    .competitor.not-competitor-licensee{ color: gold;}
    .competitor.not-validated-licensee{ color: yellowgreen}

    .category-title{ color: var(--ffvlOrangePicto); font-size: larger; }
    .staff-role { color: var(--ffvlOrangePicto); font-size:larger; }
</style>